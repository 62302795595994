import { FieldType } from '@/hosting/keap-forms-processing/field-types/field-type';
import {
    BooleanFieldType,
    CurrencyFieldType,
    DateFieldType,
    EmailFieldType,
    NumberFieldType,
    TextFieldType,
    WholeNumberFieldType,
} from '@/hosting/keap-forms-processing/field-types/type-definitions';


const FieldTypes = [
    EmailFieldType,
    CurrencyFieldType,
    WholeNumberFieldType,
    NumberFieldType,
    DateFieldType,
    TextFieldType,
    BooleanFieldType,
].reduce((prev, fieldType) => {
    prev[fieldType.type] = fieldType;

    return prev;
}, {});

export function getFieldHandler(type) {
    type = type ?? 'text';

    return FieldTypes[type] ?? FieldType({ type });
}
