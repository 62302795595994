/* eslint-disable no-console */
import {
    FORM_EVENTS, FORM_SCOPES,
    RECAPTCHA_ACTION_TOKEN,
    SMART_FORM_DISPLAY_TYPES,
    LANDING_PAGE_SOURCE_TYPE,
} from './keap-forms.constants';
import { KeapFormState } from './keap-form-state';
import { inspectForm } from './inspector/form-inspector';
import { findHostingVersion, findParentScopes, findSuccessPage } from '../keap-hosting-util';
import { KEAP_ATTRIBUTES } from '@/hosting/hosting.constants';

import { amplitude, reporting } from '@/hosting/keap-analytics';
import { KEAP_EVENT_NAMES, KEAP_EVENT_PROPERTIES } from '@/hosting/keap-analytics/keap-analytics.constants';
import { KeapEnv } from '@/hosting/keap-env';

import { getPublicFormInfo, submitSmartForm } from '@/hosting/keap-forms-processing/smart-form-api';
import { RecaptchaHelper } from '@/hosting/keap-forms-processing/recaptcha-helper';

/**
 * This class encapsulates logic for an HTML form: field binding, recaptcha, validation, and submission.
 *
 * @property {HTMLElement} element The form element used to build this handler
 * @property {?string} slug The smart-forms-api slug for this form.
 * @property {?string} tenantId The tenantId this from represents
 * @property {?object} scopes The scopes for this form: tenantId, siteId, pageId, etc.
 * @property {?string} successRedirect The URL to send the user to after a successful form submission
 * @property {KeapFormState} The form state data, including registered fields, for this form
 * @property {Promise<object>} formLoadPromise The result of loading this form from the smart-forms-api
 */
export class KeapPublicFormHandler {
    /**
     * @param {HTMLElement} keapFormElement The <keap-form> element
     * @param {HTMLFormElement} htmlFormElement The raw <form> element
     */
    constructor(keapFormElement, htmlFormElement) {
        this.hasNotifiedFirstInteraction = false;
        this.formElement = htmlFormElement;
        this.element = keapFormElement;
        this.slug = keapFormElement.getAttribute(KEAP_ATTRIBUTES.formSlug);
        this.scopes = findParentScopes(keapFormElement);
        this.successRedirect = keapFormElement.getAttribute(KEAP_ATTRIBUTES.formSuccessRedirect) || findSuccessPage(keapFormElement);
        this.formState = new KeapFormState({
            onFieldValueChanged: (event) => {
                if (!this.hasNotifiedFirstInteraction) {
                    this.hasNotifiedFirstInteraction = true;

                    amplitude.logEvent(KEAP_EVENT_NAMES.formStarted, {
                        ...this.scopes,
                        [KEAP_EVENT_PROPERTIES.formSlug]: this.slug,
                    });
                }
                this.dispatchEvent(FORM_EVENTS.fieldValueChanged, event);
            },
        });

        if (this.slug) {
            const formInfoPromise = getPublicFormInfo(this.slug);

            this.recaptchaHelperPromise = formInfoPromise.then(({ recaptchaSiteKey }) => {
                return RecaptchaHelper(recaptchaSiteKey);
            });
            this.formIdPromise = formInfoPromise.then((formInfo) => formInfo?.form?.id);
        }
    }

    async getRecaptchaToken() {
        if (!this.recaptchaHelperPromise) return null;
        const helper = await this.recaptchaHelperPromise;

        return helper.getRecaptchaToken(RECAPTCHA_ACTION_TOKEN);
    }

    get tenantId() {
        return KeapEnv.appId;
    }

    /**
     * Shorthand for dispatching an event from this form.  This will dispatch a bubbling [CustomEvent].
     * @param name The name of the event.
     * @param detail Detail data for the event.
     */
    dispatchEvent(name, detail = {}) {
        this.element.dispatchEvent(new CustomEvent(name, { bubbles: true, detail }));
    }

    /**
     * Returns a copy of all form field values.
     * @return {FormFieldSnapshot}
     */
    currentValues() {
        return this.formState.currentValues;
    }

    validate() {
        return Object.entries(this.formState.fields)
            .reduce((prev, [key, state]) => {
                const { value, errors = [] } = state.convertAndValidate();

                errors.forEach((err) => prev.errors.push([key, state.fieldLabel ?? state.fieldName, err]));
                prev.values[key] = value;

                return prev;
            }, { values: {}, errors: [] });
    }

    /**
     * Submits this form to the smart-forms-api
     */
    async submitForm(fields) {
        this.dispatchEvent(FORM_EVENTS.formSubmitStarted, fields);
        this.formState.persist();
        let recaptchaToken;

        try {
            recaptchaToken = await this.getRecaptchaToken();
        } catch (err) {
            amplitude.logEvent(KEAP_EVENT_NAMES.formError, {
                ...this.scopes,
                [KEAP_EVENT_PROPERTIES.formSlug]: this.slug,
                [KEAP_EVENT_PROPERTIES.errorCode]: 'recaptcha.failed',
            });
            this.dispatchEvent(FORM_EVENTS.formSubmitError, { code: 'recaptcha' });

            return { success: false, attempted: false };
        }

        const {
            [FORM_SCOPES.marketingPageSlug]: pageId,
            [FORM_SCOPES.marketingSiteSlug]: siteId,
        } = this.scopes;

        const postData = {
            data: fields,
            sourceType: LANDING_PAGE_SOURCE_TYPE,
            sourceUrl: window.location.href,
            sourceDisplayType: window.top === window
                ? SMART_FORM_DISPLAY_TYPES.HOSTED
                : SMART_FORM_DISPLAY_TYPES.IFRAME,
            pageId,
            siteId,
            visitId: reporting.getVisitId(),
            visitorId: reporting.getVisitorId(),
        };

        try {
            if (!this.formIdPromise) {
                return { attempted: false, success: false };
            }

            const formId = await this.formIdPromise;
            const data = await submitSmartForm({
                formId, recaptchaToken, postData,
            });

            this.dispatchEvent(FORM_EVENTS.formSubmitCompleted, { data });

            amplitude.logEvent(KEAP_EVENT_NAMES.formSubmitted, {
                ...this.scopes,
                [KEAP_EVENT_PROPERTIES.formSlug]: this.slug,
            });

            return {
                success: true,
                data,
                attempted: true,
            };
        } catch (error) {
            console.warn('Error submitting:', error);
            this.dispatchEvent(FORM_EVENTS.formSubmitError, { code: 'smart-forms-api', error });
            amplitude.logEvent(KEAP_EVENT_NAMES.formError, {
                ...this.scopes,
                [KEAP_EVENT_PROPERTIES.formSlug]: this.slug,
                [KEAP_EVENT_PROPERTIES.errorCode]: 'http.error',
            });

            return {
                attempted: true,
                success: false,
                error,
            };
        }
    }

    /**
     * Invoked after successful form submission.
     */
    handleCompletion() {
        if (this.successRedirect) {
            window.location.href = this.successRedirect;
        }
    }

    /**
     * Inspects the dom for form fields and adds them to [fields].
     */
    registerFields() {
        const hostingVersion = findHostingVersion(this.formElement) ?? 'default';

        inspectForm(this.formElement, this.formState, { hostingVersion });
        this.dispatchEvent(FORM_EVENTS.formInspectionComplete, this.formState.fields);
    }
}

